const $ = window.jQuery;
const SWAL = window.SWAL;
const Toastr = window.Toastr;

let ajax;
$.ajaxSetup({ traditional: true });

const authInterval = setInterval(updateAuthSession, 300000);

// Обновить сессию используя swagger API
function updateAuthSession() {
  $.ajax({
    type: 'PUT',
    url: Util.blackbox('/swagger/auth/session'),
    contentType: "application/json; charset=UTF-8",
    xhrFields: {
      withCredentials: true
    },
    global: false,
    complete: function(res) {
      const COMPLETE_STATUSES = [503, 401, 404];

      if (COMPLETE_STATUSES.includes(Number(res.status))) {
				clearInterval(authInterval);
				Cookie.delete('session_id');
				Cookie.delete('session_username');
				Cookie.delete('debug');
      }
    },
    error: function(err) {
      console.log('Can\'t update auth session');
      console.log('Reason: ' + err && err.statusText);
      return;
    }
  });
};

function clearAuthSession(callback) {
  $.ajax({
    type: 'DELETE',
    url: Util.blackbox('/swagger/auth/session'),
    contentType: "application/json; charset=UTF-8",
    xhrFields: {
      withCredentials: true
    },
    global: false,
    complete: callback,
    error: function(err) {
      console.log('Can\'t clear auth session');
      console.log('Reason: ' + err && err.statusText);
      return;
    }
  });
}


class Util {
	static tableToXLS(selector){
			let tab_text="<table border='2px'><tr color='#333' bgcolor='#fafafa' border='1px solid #e7ecf1'>";
			let j=0;
			const tab = document.querySelector(selector);

			for(j = 0 ; j < tab.rows.length ; j++)
			{
				if (j === 1 && tab.rows[0].innerHTML.match(/<span.*>(.*)<\/span>/g)[1] === tab.rows[1].innerHTML.match(/<span.*>(.*)<\/span>/g)[1]){
					console.log('Дублирующее поле заголовка было пропущено');
				} else{
					if (j !== tab.rows.length && j !== 0) tab_text = tab_text + "<tr>";
					tab_text=tab_text+tab.rows[j].innerHTML+"\</tr>";
				}
			}

			tab_text=tab_text+"</table>";
			tab_text= tab_text.replace(/<A[^>]*>|<\/A>/g, "");
			tab_text= tab_text.replace(/<img[^>]*>/gi,"");
			tab_text= tab_text.replace(/<input[^>]*>|<\/input>/gi, "");
			tab_text= tab_text.replace(/<td>.*display:none.*<\/td>/gi, "");

			let ua = window.navigator.userAgent;
			const msie = ua.indexOf("MSIE ");
			let txtArea1;
			let sa;
			const BOM = "\uFEFF";
			tab_text = BOM + tab_text;
			const blob = new Blob([tab_text], { encoding:"UTF-8",type:"application/vnd.ms-excel;charset=UTF-8" });

			if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
			{
				txtArea1.document.open("txt/html","replace");
				txtArea1.document.write(tab_text);
				txtArea1.document.close();
				txtArea1.focus();
				sa=txtArea1.document.execCommand("SaveAs",true,"Say Thanks to Sumit.xls");
			}
			else
				sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));
				const csvUrl = URL.createObjectURL(blob);
				window.open(csvUrl);

			return (sa);
	}

	static blackbox(url) {
		const input  = $('input[name=blackbox]');
		const blackbox = input.val().match(/\/?(.*[^\/])/) ? input.val().match(/\/?(.*[^\/])/)[1] : undefined;
		if(blackbox) {
			if(url && url.match(/\/?(.*[^\/])/)) {
				return '/' + blackbox + '/' + url.match(/\/?(.*[^\/])/)[1] + '/';
			}
		} else {
			return url;
		}
	}

	static cleanUpData(params) {
		let new_obj = params;
		if (new_obj.length) {
			const props = Object.keys(new_obj);
			for (let prop of props) {
				if(typeof new_obj[prop] === 'object') {
					delete new_obj[prop];
				}
			}
		}
		return new_obj;
	}

	static merge(...params) {
		let new_obj = {};
		for(let i = 0; i <= params.length; i++) {
			if(params[i]) {
				for(let key in params[i]) {
					if(params[i].hasOwnProperty(key)) {
						new_obj[key] = params[i][key];
					}
				}
			}
		}
		return new_obj;
	}

	static serializeObject(...params) {
		return Util.cleanUpData(Util.merge(...params));
	}

	static serialize(...params) {
		return $.param(Util.serializeObject(...params));
	}

	static escapeSelector(selector) {
		return selector.replace(/([!"#$%'()*+,./:;?@])/g, ($1, $2) => '\\\\' + $2);
	}

	static getContent(element) {
		$.ajax({
			url: '',
			type: 'post',
			success: function (data) {
				element.html(data).promise().done(() => {
					Util.initialize_all(element);
				});
			}
		});
	}

	static find(regexp, element, child) {
		let unescaped = regexp.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		let regex = new RegExp(unescaped, 'gim');
		let results = [];
		if($(element).length) {
			let match = $(element).text().match(regex);
			if(match !== null) {
				for (let c of $(child, element)) {
					if($(c).text().match(regex)) {
						results.push($(c));
					}
				}
			}
		}
		return results;
	}

	static initialize_all(element) {
		// $('.popup-ajax', element).unibutton().popup(); // TODO: WHAT IS IT?
		$(element).plugin();
		for(let table of $('.table-content', element)) {
			$(table).table();
		}
	}

	static isIE() {
		const ua = window.navigator.userAgent;

		const msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		const trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			const rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		const edge = ua.indexOf('Edge/');
		if (edge > 0) {
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	}
}

class LocalStorage {
	constructor() {}

	static clear() {
		for(let i in localStorage) {
			const regexp = /(\d+)\.(.+)/;
			const match = regexp.exec(i);
			if(match !== null) {
				if(match[1] === $('input[name=itid]').val()) {
					this.remove(i);
				}
			}
		}
		return true;
	}

	static remove(item) {
		localStorage.removeItem(item);
		return true;
	}
}



class Cookie {
	constructor() {}

	static get(name) {
		const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	static set(name, value, options) {
		options = options || {};

		var expires = options.expires;

		if (typeof expires === 'number' && expires) {
			var d = new Date();
			d.setTime(d.getTime() + expires * 1000);
			expires = options.expires = d;
		}
		if (expires && expires.toUTCString) {
			options.expires = expires.toUTCString();
		}

		value = encodeURIComponent(value);

		var updatedCookie = name + '=' + value;

		for (const propName in options) {
			const propValue = options[propName];
			updatedCookie += '; ' + propName;
			if (propValue !== true) {
				updatedCookie += '=' + propValue;
			}
		}
		document.cookie = updatedCookie;
	}

	static delete(name) {
		Cookie.set(name, '', {
			expires: -1,
			path: '/',
			domain: window.location.hostname
		});
	}
}

$(() => {
	'use strict';
	$('a.logout').click(function(event) {
		event.preventDefault();
		Cookie.delete('session_id');
		Cookie.delete('session_username');
    Cookie.delete('debug');
    function reload() {
      location.reload();
    }
    clearAuthSession(reload);
	});
});

class NextAction {
	constructor(array, caller) {
		if(array) {
			for (let action of array) {
				if (action.name) {
					this[action.name](action.data, caller);
					// } else {
					// 	Toastr.warning(`Unknown action ${action.name}`);
					// }
				} else {
					Toastr.debug('No next action');
				}
				if(action.data) {
					if(action.data.alert) {
						SWAL.success(action.data.alert);
					}
					if(action.data.message) {
						Toastr.info(action.data.message);
					}
				}
			}
		} else {
			Toastr.debug('No next action');
		}
	}

	window_reload() {
		window.location.reload();
	}

	sidepanel_reload(){
		if (!sidepanel) return;
		sidepanel.refresh();
	}

	table_reload(data, element) {
		if(element.hasClass('table-content')) {
			element.trigger('update');
		} else if(element.closest('.table-content').length) {
			element.closest('.table-content').trigger('update');
		} else {
			Toastr.warning('Caller has no table-content class or parent for this action');
		}
	}

	delete_parent_row(data, element) {
		const row = element.closest('tr');
		row.animate({opacity: 0}, 1000, ()=>{ row.remove();});
	}

	techproc_step_reload(data, el){
		$('.mt-step-col.selected').click();
	}

	table_reload_modal_caller(data, element) {
		const caller = element.closest('.modal').data('modalCaller');
		if(caller.hasClass('table-content')) {
			caller.trigger('update');
		} else if(caller.find('.table-content').length) {
			caller.find('.table-content').trigger('update');
		} else {
			Toastr.warning('Таблица не была найдена и не обновлена');
		}
	}

	open_window(data) {
		window.open(data.href, data.target);
	}

	show_fields(data) {
		if(data.cat_id) {
			for(const cat of data.cat_id) {
				for(const el of document.querySelectorAll(`[data-cat_id='${cat}']`)) {
					$(el).slideDown();
				}
			}
		}

		if(data.tids) {
			for(const tid of data.tids) {
				for(let el of Array.from(document.querySelectorAll('[data-post]')).filter(el => $(el).data('post').tid === tid)) {
					$(el).closest('.row').slideDown();
				}
			}
		}

		accordVisibility();
	}

	hide_fields(data) {
		if(data.cat_id) {
			for(const cat of data.cat_id) {
				for(const el of document.querySelectorAll(`[data-cls_id='${cat}']`)) {
					$(el).slideUp();
				}
			}
		}

		if(data.tids) {
			for(const tid of data.tids) {
				for(let el of Array.from(document.querySelectorAll('[data-post]')).filter(el => $(el).data('post').tid === tid)) {
					$(el).closest('.row').slideUp();
				}
			}
		}

		accordVisibility();
	}

	alert(data) {
		SWAL.show(data.msg, data.type);
	}
	alert_reload(data) {
		SWAL.showReload(data.msg, data.type);
	}
}

function accordVisibility () {
		// Скрыть аккордион, если все поля с .row-toggle-display имеют .d-none
		const accords = $('.panel');

		if ( !accords.length ) return;

		$(accords).each( (i, accord) => {
			const all = $('.row-toggle-display', accord);
			const hidden = $('.row-toggle-display.d-none', accord);

			if (all.length !== 0 && all.length === hidden.length) {
				accord.classList.add('d-none');
			} else if ( accord.classList.contains('d-none') ) {
				accord.classList.remove('d-none');
			}
		});
}

// setInterval(function() {
// 	$.ajax({
// 		url: Util.blackbox("/app/check_connect/"),
// 		success: function() {
// 			$('#internet-error').hide();
// 		},
// 		error: function() {
//             $('#internet-error').show();
// 		}
// 	})
// }, 10000);
